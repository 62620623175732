footer {
    -webkit-box-shadow: 0px 4px 18px 3px #00000038;
            box-shadow: 0px 4px 18px 3px #00000038;
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    p {
        text-align: center;
        color: #4A4A4A;
        width: 348px;
    }
}

@media(max-width:650px) {
    footer {
        -webkit-box-shadow: 0px 4px 18px 3px #00000000;
                box-shadow: 0px 4px 18px 3px #00000000;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        p {
            text-align: left;
            width: 90%;
            span {
                font-weight: 800;
            }
        }
    }
}
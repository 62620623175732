/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.about-us {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 100px;
    margin-bottom: 40px;
    h1{
        background: -o-linear-gradient(359.5deg, #4F2C10 -1.5%, #D28F4B 87.32%);
        background: linear-gradient(90.5deg, #4F2C10 -1.5%, #D28F4B 87.32%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        font-size: 85px;
        font-weight: bold;
        line-height: 70px;
        height: 170px;
    }

    h2 {
        font-size: 40px;
        color: #B25C03;
        width: 80%;
        margin-bottom: 20px;
    }

    div {
        position: relative;
        width: 600px;
    }

    span {
        font-weight: 700;
    }
    .main-logo {
        width: 500px;
    }
}

.wave {
    -webkit-mask-image: url(../img/wave.png);
            mask-image: url(../img/wave.png);
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    width: 100%;
    height: 580px;
    background: -o-linear-gradient(0.91deg, #D08436 2.98%, #AD5700 98.62%);
    background: linear-gradient(89.09deg, #D08436 2.98%, #AD5700 98.62%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 60px;
    position: relative;
    z-index: 2;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    h1, p {
        color: white;
    }
    h1 {
        margin-bottom: 10px;
        font-size: 27px;
    }
    div {
        position: relative;
        bottom: 30px;
        text-align: center;
        width: 380px;
    }
    img {
        margin-bottom: 20px;
    }
}

.waves {
    position: relative;
    margin-bottom: 100px;
}

.wave-bg {
    -webkit-mask-image: url(../img/wave.png);
            mask-image: url(../img/wave.png);
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    position: absolute;
    top: 20px;
    width: 100%;
    height: 580px;
    background: -o-linear-gradient(0.91deg, rgba(165, 106, 46, 0.72) 2.98%, rgba(255, 129, 0, 0.4) 98.62%);
    background: linear-gradient(89.09deg, rgba(165, 106, 46, 0.72) 2.98%, rgba(255, 129, 0, 0.4) 98.62%);
}

.wave-bg-2 {
    -webkit-mask-image: url(../img/wave.png);
            mask-image: url(../img/wave.png);
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    position: absolute;
    top: -20px;
    width: 100%;
    height: 580px;
    background: -o-linear-gradient(0.91deg, rgba(165, 106, 46, 0.72) 2.98%, rgba(255, 129, 0, 0.4) 98.62%);
    background: linear-gradient(89.09deg, rgba(165, 106, 46, 0.72) 2.98%, rgba(255, 129, 0, 0.4) 98.62%);
}

@media(max-width:650px) {
    .about-us {
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
        gap: 0;
        overflow-x: hidden;
        h1 {
            font-size: 32px;
            line-height: 35px;
            height: 80px;
            font-weight: 1000;
        }
        h2 {
            font-size: 20px;
            width: 100%;
            font-weight: 1000;
        }

        p {
            font-size: 15px;
            width: 90%;
        }

        div {
            width: 300px;
            z-index: 3;
            margin-left: 30px;
        }
        .main-logo {
            position: absolute;
            width: 271px;
            right: -40%;
        }

        img {
            &:not(.main-logo) {
                display: none;
            }
        }
    }

    .wave {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        h1 {
            display: none;
        }
        p {
            font-size: 12px;
            &:nth-child(1) {
                position: relative;
                top: 50px;
            }
        }
        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 80%;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        }
        img {
            position: relative;
            right: 20px;
            margin: 0;
        }
    }
}

@media(max-height:500px) {
    .about-us {
        position: relative;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
        gap: 0;
        overflow-x: hidden;
        h1 {
            font-size: 32px;
            line-height: 35px;
            height: 80px;
            font-weight: 1000;
        }
        h2 {
            font-size: 20px;
            width: 100%;
            font-weight: 1000;
        }

        p {
            font-size: 15px;
            width: 90%;
        }

        div {
            width: 300px;
            z-index: 3;
            margin-left: 30px;
        }
        .main-logo {
            position: absolute;
            width: 271px;
            right: -40%;
        }

        img {
            &:not(.main-logo) {
                display: none;
            }
        }
    }

    .wave {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        h1 {
            display: none;
        }
        p {
            font-size: 12px;
            &:nth-child(1) {
                position: relative;
                top: 50px;
            }
        }
        div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 80%;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        }
        img {
            position: relative;
            right: 20px;
            margin: 0;
        }
    }
}
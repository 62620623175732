/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@700&family=Montserrat&display=swap');
.advertisement-main {
    width: 80%;
    margin: 140px auto 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.main-text {
    text-align: center;
    font-size: 40px;
    font-family: 'Jost';
    line-height: 48px;
}

.advertisement-btn {
    text-align: center;
    width: 87px;
    color: white;
    text-decoration: none;
    margin: auto;
    background: -o-linear-gradient(333deg, #6C2504 1.00%, #B48C64 90.42%);
    background: linear-gradient(117deg, #6C2504 1.00%, #B48C64 90.42%);
    border-radius: 50px;
    padding: 14px 50.44px 14px 50.19px;
}

.advertisement-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 30px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 30px 0;
    .text-container {
        width: 60%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 30px;
        h1 {
            font-size: 27px;
            background: -webkit-gradient(linear, left top, left bottom, from(#FC7F00), to(#925b20));
            background: -o-linear-gradient(top, #FC7F00 0%, #925b20 100%);
            background: linear-gradient(180deg, #FC7F00 0%, #925b20 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        p {
            span {
                font-weight: 700;
            }
        }
    }
    .img-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        img {
            margin-bottom: 30px;
        }
        #backpain {
            width: 500px;
            height: 290px;
        }

        #runninggirl {
            width: 500px;
        }

        .second-and-third-img {
            width: 400px;
            height: 260px;
        }
    }
}

@media(max-width:650px) {
    .advertisement-section {
        margin-top: 0;
        margin-bottom: 40px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 0;
        .img-container {
            img {
                &:not(.mobile) {
                    display: none;
                }
            }
        }
        .text-container {
            width: 80%;
            gap: 15px;
            h1 {
                font-size: 17px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    .main-text {
        font-size: 20px;
        line-height: 17.5px;
        width: 90%;
        margin: 0 auto 20px;
    }
    .advertisement-main {
        margin-top: 80px;
        margin-bottom: 0;
        width: 100%;
    }
}

@media(max-height:500px) {
    .advertisement-section {
        margin-top: 0;
        margin-bottom: 40px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 0;
        .img-container {
            img {
                &:not(.mobile) {
                    display: none;
                }
            }
        }
        .text-container {
            width: 80%;
            gap: 15px;
            h1 {
                font-size: 17px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    .main-text {
        font-size: 20px;
        line-height: 17.5px;
        width: 90%;
        margin: 0 auto 20px;
    }
    .advertisement-main {
        margin-top: 80px;
        margin-bottom: 0;
        width: 100%;
    }
}
.workers-grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 350px 50px 350px 50px 350px;
    grid-template-columns: 350px 350px 350px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
    margin-bottom: 70px;
    .worker {
        -webkit-box-shadow: 0px 0px 8px 1px #00000040;
                box-shadow: 0px 0px 8px 1px #00000040;
        padding: 15px;
        border-radius: 19px;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        h1 { 
            font-size: 26px;
        }
        p {
            margin-top: 15px;
            width: 100%;
        }
        a {
            display: inline-block;
            width: 207px;
            margin: 10px auto 0;
            color: black;
            text-decoration: none;
            padding: 14px 20px;
            color: white;
            text-align: center;
            border-radius: 50px;
            background: -o-linear-gradient(358.31deg, #6C2504 1%, #B48C64 90.42%);
            background: linear-gradient(91.69deg, #6C2504 1%, #B48C64 90.42%);
        }
    }
}

@media(max-width:650px) {
    .workers-grid {
        -ms-grid-columns: 90%;
        grid-template-columns: 90%;
        .worker {
            h1 {
                font-size: 15px;
            }
            p {
                font-size: 10px;
            }
            a {
                font-size: 9px;
                padding: 10px 0;
                width: 100px;
            }
        }
    }
}

@media(max-height:500px) {
    .workers-grid {
        -ms-grid-columns: 90%;
        grid-template-columns: 50%;
        .worker {
            height: 175px;
            h1 {
                font-size: 15px;
            }
            p {
                font-size: 10px;
            }
            a {
                font-size: 9px;
                padding: 10px 0;
                width: 100px;
            }
        }
    }   
}